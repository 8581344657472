$(function () {
    initCalculator();

    function isotopeBlocks() {
        $('.tips-tricks-cards').isotope({itemSelector: '.blok-categorie', percentPosition: true, masonry: {columnWidth: '.blok-categorie'}});
    }

    isotopeBlocks();

    $(".language").on("click", function () {
        $('.drop-languages').toggle();
    });

    $('.box-2-1 a .mask').mouseenter(function () {
        setTimeout(function () {
            $('.box-2-1 .white-btn').addClass('hover-on');
        }, 50);
    });

    $('.box-2-1 a .mask').mouseleave(function () {
        if ($(window).width() < 993) {
            setTimeout(function () {
                $('.box-2-1 .white-btn').removeClass('hover-on');
            }, 200);
        } else {
            setTimeout(function () {
                $('.box-2-1 .white-btn').removeClass('hover-on');
            }, 500);
        }
    });

    $('.box-2-1 a .mask').mouseenter(function () {
        $('.box-2-1 .french p').addClass('hover-on');
    });

    $('.box-2-1 a .mask').mouseleave(function () {
        $('.box-2-1 .french p').removeClass('hover-on');
    });

    $('.box-2-1').mouseenter(function () {
        $('.box-2-text p').animate({opacity: 0})
        $('.box-2-text p').clearQueue();
    });

    $('.box-2-1').mouseleave(function () {
        $('.box-2-text p').animate({opacity: 1})
    });

    var product = $('.product-view');
    $(product).click(function () {
        var id = $(this).attr('data-product-id');
        $(product).removeClass('product-active');
        $(this).addClass('product-active');
        $('.arrow').removeClass('arrow-active');
        $('#arrow-' + id).addClass('arrow-active');
    });

    var $productDetailContainer = $('<div class="product-detail-container" />');
    var itemsPerRow;
    var currentItem;
    var timeout;
    var onWindowResize = function (itemCount) {
        var screenWidth = $(window).width();
        itemsPerRow = 1;
        if (screenWidth >= 992) {
            itemsPerRow = 3;
        }
        else if (screenWidth >= 768 && screenWidth <= 991) {
            itemsPerRow = 2;
        }
        if (currentItem) {
            $(currentItem).click();
        }
    };
    $(window).on('resize', function () {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(onWindowResize, 500);
    }).trigger('resize');
    $('.product-base').on('click', function () {
        var id = $(product).attr('data-product-id');
        var itemID = $(this).attr('data-counter');
        var clickedRow = Math.ceil((itemID * 1) / itemsPerRow);
        var totalItems = $(this).parents('.product-row').find('.product-base').length;
        var lastElementRow = Math.min(itemsPerRow * clickedRow, totalItems);
        var $currentProduct = $(this).find('.product-detail');
        currentItem = this;
        $productDetailContainer.html($currentProduct.clone());
        $(this).parents('.product-row').find('[data-counter=' + lastElementRow + ']').after($productDetailContainer);
        $('.close-x').click(function () {
            $($productDetailContainer).remove();
            $('.arrow').removeClass('arrow-active');
            $(product).removeClass('product-active');
            $('#' + id).removeClass('product-detail-active');
        });
    });
    $('#subscribe input').focus(function () {
        $('.g-recaptcha').css('visibility', 'visible');
    });
    $(function () {
        $('#sortQuery').on("change", function (e) {
            var filter = $(this).val();
            document.location.href = CRAFT.CURRENT_URL + '?order=' + filter;
        });
    });
    $('.tips-tricks-cards').isotope({itemSelector: '.blok-categorie', percentPosition: true, masonry: {columnWidth: '.blok-categorie'}});
    $('.category-listing').on('click', function () {
        var ct = $(this).attr('data-categorie');
        $('#' + ct).toggleClass('active');
        if (ct == 'Leuk') {
            $('.main-ct-' + ct).toggleClass('ct-' + ct);
            $('.main-ct-' + ct).removeClass('typeNone');
        }
        else if (ct == 'Handig') {
            $('.main-ct-' + ct).toggleClass('ct-' + ct);
            $('.main-ct-' + ct).removeClass('typeNone');
        }
        else if (ct == 'Lekker') {
            $('.main-ct-' + ct).toggleClass('ct-' + ct);
            $('.main-ct-' + ct).removeClass('typeNone');
        }
        else {
            $('.main-ct-' + ct).toggleClass('ct-' + ct);
            $('.main-ct-' + ct).removeClass('typeNone');
        }

        isotopeBlocks();
    });

    $('.product-listing').on('click', function () {
        var tp = $(this).attr('data-product');
        $('#' + tp).toggleClass('active');
        $('#type-' + tp).toggleClass('active');
        $('.main-tp-' + tp).toggleClass('typeNone');

        isotopeBlocks();
    });

    $('a').each(function () {
        if (jQuery.trim($(this).attr('href')) == "")
            $(this).css('visibillity', 'hidden');
    });
});

function submitForm() {
    $('#subscribe').submit();
}

function initCalculator() {
    if ($('#calculator').length > 0) {
        $('input[type=radio]').on('change', function (e) {
            $span = $(this).next();

            if ($(this).is(':checked')) {
                $(this).closest('div').find('span').removeClass('checked');
                $span.addClass('checked');
            }

            if ($(this).attr('name') == 'shape') {
                if ($(this).is(':checked')) {
                    if ($(this).val() == 'round') {
                        $('.sizes_square').hide();
                        $('.sizes_round').show();
                        $('.sizes_other').hide();
                    } else if ($(this).val() == 'other') {
                        $('.sizes_square').hide();
                        $('.sizes_round').hide();
                        $('.sizes_other').show();
                    } else {
                        $('.sizes_square').show();
                        $('.sizes_round').hide();
                        $('.sizes_other').hide();
                    }
                }
            } else {
                $('#calculatorResults').hide();
            }

            calculate();
        });

        $('input[type=text]').on('keyup', function (e) {
            $('#calculatorResults').hide();
            calculate();
        });

        $('input[type=radio]:checked').change();

        initCalcButton();
    }
}

function calculate() {
    $('.noresults_wrapper').hide();

    $.ajax({
        dataType: 'json',
        method: 'post',
        url: "/nl/calculate",
        data: $('#calc_form').serializeArray(),
        success: function (result) {
            if(result.request.count == 0 && result.request.dimensions) {
                $('.noresults_wrapper').show();
            }

            $("#calc_button").text(result.request.count + ' matches');
            $("#calc_button").attr('data-results', JSON.stringify(result.data));
            $("#calc_button").attr('data-request', JSON.stringify(result.request));
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
}

function initCalcButton() {
    $("#calc_button").on('click', function (e) {
        e.preventDefault();

        if ($(this).attr('data-request')) {
            var input = $.parseJSON($(this).attr('data-request'));
            if (input.dimensions) {
                $.ajax({
                    dataType: 'json',
                    method: 'post',
                    url: "/nl/save-calculator-result",
                    data: input,
                    success: function (result) {
                        if (result) {
                            console.log('saved');
                        }
                    },
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                });
            }
        }

        var data = $.parseJSON($(this).attr('data-results'));

        $('#calculatorResults tbody tr').remove();

        if ($(data).length) {
            $(data).each(function (i, el) {
                tr = $('<tr></tr>');
                tr.append(
                    $('<td></td>').html('<img src="/img/calculator/' + el.sku + '.jpg" alt="' + el.title + '">')
                );
                tr.append(
                    $('<td></td>').html('<a href="' + el.link + '">' + el.title + '</a>')
                );
                // tr.append(
                //     $('<td></td>').html(el.width + ' cm')
                // );
                // tr.append(
                //     $('<td></td>').html(el.height + ' cm')
                // );

                $('#calculatorResults tbody').append(tr);
            });
            $('#calculatorResults').show();
        } else {
            $('#calculatorResults').hide();
        }
    });

}
function invisiblereCaptchaContact() {
    document.getElementById('frmContact').submit();
}
function invisiblereCaptchaKlacht() {
    document.getElementById('frmKlacht').submit();
}